import ApiService, { RequestResponse } from "@/core/services/ApiService";
import { ref, Ref } from "vue";

interface UsableConfigurations {
  configurations: Ref<unknown>;
  getConfigurations: (queryParams?: any) => void;
  //   getUser: (id: number) => void;
}

export const useConfigurations = (): UsableConfigurations => {
  const configurations: Ref<unknown> = ref();

  const getConfigurations = async (queryParams = "") => {
    const { error, data } = (await ApiService.get(
      "/AbpUserConfiguration/GetAll?d=" + queryParams
    )) as unknown as RequestResponse<unknown>;

    if (error == null) {
      configurations.value = data as unknown;
    }
  };

  return {
    configurations,
    getConfigurations,
  };
};
