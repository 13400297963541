
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { object, string, boolean } from "yup";
import Tenancy from "@/components/widgets/extras/Tenancy.vue";
import { useConfigurations } from "@/composables/useConfigurations";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
    Tenancy,
  },
  setup() {
    const store = useStore();
    // const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    const { configurations, getConfigurations } = useConfigurations();
    //Create form validation object
    const login = object().shape({
      userNameOrEmailAddress: string()
        .min(4)
        .required()
        .label("Username or email"),
      password: string().min(4).required().label("Password"),
      rememberClient: boolean(),
    });
    // onMounted(async () => {
    //   await getConfigurations(Date.now());
    //   await store.commit(Mutations.SET_CONFIG, configurations.value);
    // });

    //Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      await store.dispatch(Actions.LOGIN2, values);
      const error = store.getters.getErrors;
      if (!error) {
        await getConfigurations(Date.now());
        await store.commit(Mutations.SET_CONFIG, configurations.value);
        window.location.href = "/dashboard";
      } else {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});
